.sc-page-spinner-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .sc-page-spinner {
  }
}
