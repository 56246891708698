html,
body,
#root {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

#root {
  overflow: auto;
}
